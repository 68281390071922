import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/slovenia-green/sloveniagreen_img1.png'
import img2 from '../../assets/images/slovenia-green/sloveniagreen_img2.png'
import img3 from '../../assets/images/slovenia-green/sloveniagreen_img3.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionFullImage,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import { HeadlineM, TitleM, ParagraphL, ParagraphM, ParagraphS } from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'UX',
    'UI',
    'Vodenje družbenih omrežij',
    'Digitalno oglaševanje',
    'Grafično oblikovanje',
    'Tekstopisje',
    'Email marketing',
    'Google oglasi',
    'UX',
    'UI',
    'Vodenje družbenih omrežij',
    'Digitalno oglaševanje',
    'Grafično oblikovanje',
    'Tekstopisje',
    'Email marketing',
    'Google oglasi',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Slovenia Green"
        description="Za naročnika smo oblikovali spletno stran Slovenia Green, vzpostavili kanale na družbenih omrežjih in izvedli promocijske aktivnosti za pridobivanje gostov."
        image={img1}
      />
      <Header
        logoBackground={colors.purple}
        logoBorder={colors.purple}
        linkText={colors.purple}
        menuLines={colors.purple}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Slovenia Green" />
          </div>
          <Square background={colors.red} className="title">
            <ParagraphM color={colors.white}>Konzorcij Slovenia Green</ParagraphM>
            <HeadlineM color={colors.purple}>Slovenia Green</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.purple}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Oblikovati spletno stran, ki bo zastavljena tako, da bo funkcionalna tudi ob širitvi
              zelene sheme Slovenia Green.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Za naročnika smo oblikovali spletno stran Slovenia Green, vzpostavili kanale na
              družbenih omrežjih in izvedli promocijske aktivnosti za pridobivanje gostov.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding reduce-bottom-padding">
            <Content>
              {/* Example Title */}
              <h2>Oblikovanje spletne strani</h2>

              {/* Example Paragraph */}
              <p>
                Spletna stran je namenjena temu, da popotniku pomaga, da je bolj trajnosten in
                sprejema bolj trajnostne odločitve medtem ko potuje po Sloveniji. Osrednja tema
                spletne strani so Slovenia Green poti, ki med sabo povezujejo destinacije,
                prenočišča, restavracije in doživetja, ki so prejela Slovenia Green znak.
              </p>
              <p>
                Za navigacijo po strani lahko uporabnik uporablja zemljevid, ali pa brska po
                različnih kategorijah v meniju.
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img2} alt="Gengigel" />
          </SectionFullImage>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img3} alt="Gengigel" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Title */}
              <h2>Družbena omrežja</h2>

              {/* Example Paragraph */}
              <p>
                Za naročnika smo vzpostavili in vodimo družbena omrežja Facebook in Instagram. Za
                naročnika smo izvajali tudi promocijske kampanje za Slovenia Green poti. Če je
                uporabnik oddal e-mail, je prejel sporočilo s povezavo preko katere si je lahko
                brezplačno prenesel navigacijske podatke za samostojno potovanje po teh poteh.
              </p>
              <p>Avtor naslovne fotografije: Ciril Jazbec, vir: slovenia.info</p>
            </Content>
          </SectionRichText>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/gengigel">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/to-poletje-bo-nord">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
